import React, { useCallback, useRef } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { DI_TOKENS } from '@shared/constants/di';
import { INavigationVM } from '@shared/types/navigation-vm';
import { reaction } from 'mobx';

import { Tabbar } from '../tabbar';

import { styles } from './tabbar-container.styles';

export interface TabbarContainerProps extends AppWithStyles<typeof styles> {
  children?: React.ReactNode;
}

const TabbarContainerComponent: React.FC<TabbarContainerProps> = ({ classes, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigationVM = appInject<INavigationVM>(DI_TOKENS.navigationViewModel);

  reaction(
    () => navigationVM.recall,
    () => scrollToTop(),
  );

  const scrollToTop = useCallback(() => {
    ref.current?.scrollIntoView();
  }, []);

  return (
    <div className={classes.root} ref={ref}>
      {children}
      <Tabbar />
    </div>
  );
};

export default appWithStyles(styles)(TabbarContainerComponent);
