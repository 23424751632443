import { appInject } from '@core/di/utils';
import { t } from '@lingui/macro';
import { ITopUpForm } from '@modules/new-private/wallets/top-up/components/top-up/top-up.validator';
import { DI_TOKENS } from '@shared/constants/di';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';
import { LimitsModel } from '@shared/models/wallets/limits-model';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IAuthService } from '@shared/types/auth-service';
import { ICardsService } from '@shared/types/card-service';
import { ICardsCooldownCheckVM } from '@shared/types/cards-cooldown-check-vm';
import { IErrorMessagesService } from '@shared/types/error-messages-service';
import { INotificationService } from '@shared/types/notification-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { ITransactionsNotificationService } from '@shared/types/transactions-notification-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { sanitizedAmount } from '@shared/utils/metals';

export class TopUpViewModel {
  private cardsCooldownCheckVM = appInject<ICardsCooldownCheckVM>(DI_TOKENS.cardsCooldownCheckVM);
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private cardService = appInject<ICardsService>(DI_TOKENS.cardService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private errorMessagesService = appInject<IErrorMessagesService>(DI_TOKENS.errorMessagesService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private transactionsNotificaionService = appInject<ITransactionsNotificationService>(
    DI_TOKENS.transactionsNotificationService,
  );
  private _document = null as TermsDocumentModel | null;
  private _wallets: Array<WalletsListModel> = [];
  private _limits: LimitsModel;
  private _cards: Array<CreditCardListModel>;
  private _isActiveMaintenanceMode: boolean = false;
  private minLimitError: string;
  private maxLimitError: string;

  get isActiveMaintenanceMode() {
    return this._isActiveMaintenanceMode;
  }

  get document() {
    return this._document;
  }

  get wallets() {
    return this._wallets;
  }

  get cards() {
    return this._cards;
  }

  get currency() {
    return this.ratesVM.currency;
  }

  get amountError(): boolean {
    return Boolean(this.maxLimitError) || Boolean(this.minLimitError);
  }

  get limitError() {
    return this.minLimitError || this.maxLimitError;
  }

  get minCardLimit() {
    return minDigitsAfterDot(amountPipe(this._limits?.minCardLimit || 0), 2);
  }

  get maxCardLimit() {
    return minDigitsAfterDot(amountPipe(this._limits?.maxCardLimit || 0), 2);
  }

  get minWalletLimit() {
    return minDigitsAfterDot(amountPipe(this._limits?.minWalletLimit || 0), 2);
  }

  get maxWalletLimit() {
    return minDigitsAfterDot(amountPipe(this._limits?.maxWalletLimit || 0), 2);
  }

  getProductTypeByCurrency(currency: string) {
    return this.productsVM.getProductTypeByCurrency(currency);
  }

  getProductByCurrency(currency: string) {
    return this.productsVM.getProductByCurrency(currency);
  }

  async downloadWallets() {
    this._wallets = await this.walletsService.getList(this.ratesVM.currency);
  }

  async downloadLimits() {
    this._limits = await this.walletsService.getLimits();
  }

  async downloadCards() {
    await this.cardsCooldownCheckVM.fetchCooldownInfo();
    this._cards = await this.cardService.getCardList();
  }

  async fetchMaintenanceModeStatus() {
    await this.usersService.fetchMaintenanceModeStatus();
    this._isActiveMaintenanceMode = this.usersService.isActiveMaintenanceMode;
  }

  activePaymentSystem() {
    return this.paymentService.name;
  }

  handleAddNewCard = async (
    paymentInfo: any,
    save: boolean,
  ): Promise<CreditCardListModel | null> => {
    let savedCard = null;
    try {
      const { email } = await this.authService.getUserInfo(true);
      const newPaymentMethod = await this.paymentService.createPaymentMethod(
        Object.assign(paymentInfo, { email, isTemporary: !save }),
      );
      savedCard = await this.cardService.saveCard(newPaymentMethod);
      this._cards.push(savedCard);
    } catch (e: HttpErrorResponse | any) {
      const { response, message } = e;

      if (response) {
        throw response.findFirstErrorMessage() || message;
      } else {
        throw message || t`Your card was declined.`;
      }
    }
    return savedCard;
  };

  createDepositWallet = async (data: {
    paymentMethodId: string;
    amount: number;
    walletId: string;
  }) => {
    const deviceDataInitializationInfo = await this.walletsService.deviceDataInitialization(
      data.paymentMethodId,
    );
    const deposit = await this.walletsService.createDepositWallet(
      data.walletId,
      data.paymentMethodId,
      sanitizedAmount(data.amount),
      deviceDataInitializationInfo.asJson.transactionId,
      deviceDataInitializationInfo.asJson.sessionId,
    );
    if (!deposit.asJson.isSucceeded) {
      if (deposit.error && deposit.error.declineCode) {
        throw t({ id: this.errorMessagesService.get(deposit.error.declineCode) });
      } else {
        throw 'Something went wrong';
      }
    }
    return deposit;
  };

  checkLimits(amount: number, displayedFiatCurrency: string) {
    if (!this._limits?.asJson?.creditCards || !this._limits?.asJson?.fiatWallets) {
      return;
    }
    const currentLimit = this._limits?.asJson?.creditCards;
    this.minLimitError =
      amount < currentLimit.lowerLimit
        ? t`Min limit` +
          ' ' +
          minDigitsAfterDot(amountPipe(currentLimit.lowerLimit), 2) +
          ' ' +
          displayedFiatCurrency
        : '';
    this.maxLimitError =
      amount > currentLimit.upperLimit
        ? t`Max limit` +
          ' ' +
          minDigitsAfterDot(amountPipe(currentLimit.upperLimit), 2) +
          ' ' +
          displayedFiatCurrency
        : '';
  }

  handleConfirmation = async (transactionId: string) => {
    await this.walletsService.confirmPaymentTransaction(transactionId);
  };

  handle3DSecure = async (transactionId: string, paymentResult: ResponsePaymentResult) => {
    return await this.paymentService.handle3DSecure(paymentResult, transactionId);
  };

  declineTransaction = async (transactionId: string) => {
    await this.walletsService.declinePaymentTransaction(transactionId);
  };

  async fetchDocument() {
    this._document = await this.usersService.getDocument(DocumentsTitle.DEPOSIT);
  }

  saveState = (data: ITopUpForm) => {
    localStorage.setItem(`deposit_in_progress:${this.authService.userId}`, JSON.stringify(data));
  };

  loadState(): { amount: number; paymentMethodId: string; walletId: string } {
    return JSON.parse(
      localStorage.getItem(`deposit_in_progress:${this.authService.userId}`) || '{}',
    );
  }

  removeState() {
    localStorage.removeItem(`deposit_in_progress:${this.authService.userId}`);
  }

  signOut() {
    this.authService.signOut();
  }

  showSuccessfulTransactionMessage(amount: number, currency: string) {
    this.showTransactionMessage(amount, currency, 'successful', '');
  }

  showInProgressTransactionMessage(amount: number, currency: string) {
    this.showTransactionMessage(amount, currency, 'inProgress', '');
  }

  showCanceledTransactionMessage(amount: number, currency: string) {
    this.showTransactionMessage(amount, currency, 'canceled', '');
  }

  private showTransactionMessage(amount: number, currency: string, status: string, id: string) {
    this.transactionsNotificaionService.showNotification({
      amount,
      currency,
      status,
      type: TransactionMessageType.TOP_UP,
      id,
    });
  }

  showSuccess(text: string) {
    this.notificationsService.showSuccess(text);
  }

  showError(text: string) {
    this.notificationsService.showError(text);
  }
}
